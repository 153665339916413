import { createStore, applyMiddleware, compose, combineReducers } from 'redux';

import { appReducer } from './reducers/appReducer';
import { authReducer } from './reducers/authReducer';

import createSagaMiddleware from 'redux-saga';
import rootSaga from './sagas/rootSaga';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const sagaMiddleware = createSagaMiddleware();

const rootReducer = combineReducers({
  app: appReducer,
  auth: authReducer
});

export const store = createStore(rootReducer, composeEnhancers(
  applyMiddleware(sagaMiddleware)
));

sagaMiddleware.run(rootSaga);
