import React from 'react';

export default (props) => {
  const { cx, cy, r, color } = props;
  if(cy === null) {
    return null;
  };
  return (
    <circle cx={cx} cy={cy} r={r} stroke={color} strokeWidth={1} fill={color} fillOpacity="0.7"/>
  );
};