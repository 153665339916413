import React, { Component, Fragment } from "react";
import Backdrop from '../Backdrop/Backdrop';
import styles from './Modal.module.css';

class Modal extends Component {

   shouldComponentUpdate(nextProps, nextState) {
      return nextProps.show !== this.props.show || nextProps.children !== this.props.children;
   }

   render() {
      return (
         <Fragment>
            <Backdrop show={this.props.show} zIndex="600"/>
            <div className={styles.modal} style={{
                  transform: this.props.show ? 'translate(-50%,-50%)' : 'translateY(100vh)',
                  opacity: this.props.show ? '1' : '0'
               }}>
               <div className="modal-children-area">
               {this.props.children}
               </div>
            </div>
         </Fragment>
      )
   }
}

export default Modal;
