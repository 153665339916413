import React, { Component } from 'react';
import './PrintPage.module.css';
import '../../../components/Chart/Print/LineChartComponent/LineChartComponent';
import '../../../components/Chart/Print/BarChartComponent/BarChartComponent';
import '../../../components/Chart/Print/ScatterChartWrapper/ScatterChartWrapper';
import '../../../components/ValuesArea/ValuesArea';
import '../../../components/ValuesAreaOfRain/ValuesAreaOfRain';
const moment = require('moment-timezone');
moment.updateLocale('ja', {
  weekdaysShort: ["日","月","火","水","木","金","土"],
});

class PrintPage extends Component {
  render () {
    return (
      <div id="container"></div>
    )
  }
}

export default PrintPage;