import * as types from './actionTypes';

export function showAlert(alert) {
  return {
    type: types.app.SHOW_ALERT,
    payload: alert
  }
}

export function hideAlert() {
  return {
    type: types.app.HIDE_ALERT
  }
}
