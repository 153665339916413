import React from 'react';

const Backdrop = (props) => {
  let Backdrop_style = {
    width: "100%",
    height: "100%",
    position: "fixed",
    zIndex: props.zIndex || "400",
    left: 0,
    top: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  }
  let backdrop = props.show ? <div style={Backdrop_style}></div> : null;
  return backdrop;
}

export default Backdrop;
