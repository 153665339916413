import React from 'react';
import styles from './ValuesAreaOfRain.module.css';
import MediaQuery from "react-responsive";

export default ({values_obj, isComposed, graph_type, digits, names}) => {
  let values_area_of_rain;
  const keys = Object.keys(values_obj);
  if(isComposed) {
    let order;
    graph_type.forEach((key, index) => {
      const category = key.split('%%%')[1];
      if(category === 'rain') {
        order = index;
      }
    });
    values_area_of_rain = keys.map((key, index) => {
      const values = values_obj[key].values;
      const digit = digits[key];
      return (
        <div className={styles.values_area_outer} key={index}>
          <div className={styles.values_area_wrapper_2}>
            <div className={`${styles.values_area} ${styles.is_two}`}>
            <MediaQuery query="(max-width: 767px)">
              <div>
                <span>[</span>
                <span className={order === 0 ? styles.first : styles.second}>{names[key]}</span>
                <span>]</span></div>
              <div>1時間: <span>{values.hour.toFixed(digit)}</span></div>
              <div>24時間: <span>{values.today.toFixed(digit)}</span></div>
              <div>1ヶ月: <span>{values.month.toFixed(digit)}</span></div>
              <div>[mm]</div>
            </MediaQuery>
            <MediaQuery query="(min-width: 768px)">
              <div className={styles.legend_wrapper}>
                <i className={order === 0 ? `fas fa-square ${styles.legend}` : `fas fa-square ${styles.legend2}`}></i>
              </div>
              <div><span>[</span>{names[key]}<span>]</span></div>
              <div>1時間: <span>{values.hour.toFixed(digit)}</span></div>
              <div>24時間: <span>{values.today.toFixed(digit)}</span></div>
              <div>1ヶ月: <span>{values.month.toFixed(digit)}</span></div>
              <div>[mm]</div>
            </MediaQuery>
            </div>
          </div>
        </div>
      );
    });
  } else if(keys.length === 2) {
    const divs = keys.map((key, index) => {
      const values = values_obj[key].values;
      const digit = digits[key];
      return (
        <div className={`${styles.values_area} ${styles.is_two}`} key={`div-${index}`}>
          <MediaQuery query="(max-width: 767px)">
            <div>
              <span>[</span>
              <span className={index === 0 ? styles.first : styles.second}>{names[key]}</span>
              <span>]</span></div>
            <div>1時間: <span>{values.hour.toFixed(digit)}</span></div>
            <div>24時間: <span>{values.today.toFixed(digit)}</span></div>
            <div>1ヶ月: <span>{values.month.toFixed(digit)}</span></div>
            <div>[mm]</div>
          </MediaQuery>
          <MediaQuery query="(min-width: 768px)">
            <div className={styles.legend_wrapper}>
              <i className={index === 0 ? `fas fa-square ${styles.legend}` : `fas fa-square ${styles.legend2}`}></i>
            </div>
            <div><span>[</span>{names[key]}<span>]</span></div>
            <div>1時間: <span>{values.hour.toFixed(digit)}</span></div>
            <div>24時間: <span>{values.today.toFixed(digit)}</span></div>
            <div>1ヶ月: <span>{values.month.toFixed(digit)}</span></div>
            <div>[mm]</div>
          </MediaQuery>
        </div>
      );
    });
    values_area_of_rain = (
      <div className={styles.values_area_outer}>
        <div className={styles.values_area_wrapper_2}>
            { divs }
        </div>
      </div>
    );
  } else {
    values_area_of_rain = keys.map((key, index) => {
      const values = values_obj[key].values;
      const digit = digits[key];
        return (
          <div className={styles.values_area_outer} key={index}>
            <div className={styles.values_area_wrapper_1}>
                <div className={styles.values_area}>
                  <div>1時間: <span>{`${values.hour.toFixed(digit)}mm`}</span></div>
                  <div>24時間: <span>{`${values.today.toFixed(digit)}mm`}</span></div>
                  <div>1ヶ月: <span>{`${values.month.toFixed(digit)}mm`}</span></div>
                </div>
            </div>
          </div>
        );
      });
  }
  return values_area_of_rain;
};