import React, { Component, Fragment } from 'react';
import Backdrop from '../Backdrop/Backdrop';

import { CSSTransition } from 'react-transition-group';
import styles from './Alert.module.css';

class Alert extends Component {
  render() {
    const animationTiming = {
      enter: 150,
      exit: 100
    };

    const defaultStyle = {
      display: 'block'
    }

    let cancel_button = (
      <span
        className={styles.alert_close_btn}
        onClick={(event) => this.props.onClose(event)}
      >
        閉じる
      </span>
    );

    let icon = <i style={{ fontSize: '2em', color: 'red', marginBottom: '10px'}} className="fa fa-times-circle"></i>

    return (
      <Fragment>
        <Backdrop show={this.props.show} zIndex="400" />
        <CSSTransition
          mountOnEnter
          unmountOnExit
          in={this.props.show}
          timeout={animationTiming}
          classNames={{
            enter: '',
            enterActive: styles.ModalOpen,
            exit: '',
            exitActive: styles.ModalOpen
          }}>
            {state => (
              <div style={defaultStyle}>
                <div className={styles.alert_style}>
                  <div>
                    { icon }
                    <p>{ this.props.message }</p>
                    <br/>
                  </div>
                  { cancel_button}
                </div>
              </div>
            )}
          </CSSTransition>
      </Fragment>
    )
  }
}

export default Alert;
