import React from 'react';
import styles from './ValuesArea.module.css';
import MediaQuery from "react-responsive";

export default ({values, isComposed, graph_type}) => {
  let values_area;
  if(isComposed) {
    let order;
    graph_type.forEach((key, index) => {
      const category = key.split('%%%')[1];
      if(category !== 'rain') {
        order = index;
      }
    });
    values_area = values.map((dt, index) => {
      const key = Object.keys(dt)[0];
      const digit = dt[key].digits;
      return (
        <div className={styles.values_area_outer} key={index}>
          <div className={styles.values_area_wrapper_2}>
            <div className={`${styles.values_area} ${styles.is_two}`} key={`div-${index}`}>
            <MediaQuery query="(max-width: 767px)">
              <div>
                <span >[</span>
                <span className={order === 0 ? styles.first : styles.second}>{dt[key].name}</span>
                <span>]</span>
              </div>
              <div>最大<span className={styles.none}>値</span>:<span className={styles.max}>{dt[key].max.toFixed(digit)}</span></div>
              <div>最小<span className={styles.none}>値</span>:<span className={styles.min}>{dt[key].min.toFixed(digit)}</span></div>
              <div>平均<span className={styles.none}>値</span>:<span className={styles.average}>{dt[key].average.toFixed(digit)}</span></div>
              <div>[{dt[key].unit}]</div>
            </MediaQuery>
            <MediaQuery query="(min-width: 768px)">
              <div className={styles.legend_wrapper}>
                <i className={order === 0 ? `fas fa-square ${styles.legend}` : `fas fa-square ${styles.legend2}`}></i>
              </div>
              <div>
                <span >[</span>{dt[key].name}<span>]</span>
              </div>
              <div>最大値: <span className={styles.max}>{dt[key].max.toFixed(digit)}</span></div>
              <div>最小値: <span className={styles.min}>{dt[key].min.toFixed(digit)}</span></div>
              <div>平均値: <span className={styles.average}>{dt[key].average.toFixed(digit)}</span></div>
              <div>[{dt[key].unit}]</div>
            </MediaQuery>
            </div>
          </div>
        </div>
      );
    });
  } else if(values.length === 2) {
    const keyA = Object.keys(values[0])[0];
    const keyB = Object.keys(values[1])[0];
    const nameA = values[0][keyA].name;
    const nameB = values[1][keyB].name;
    let is_same_name = false;
    if(nameA === nameB) {
      is_same_name = true;
    };
    const divs = values.map((dt, index) => {
      const key = Object.keys(dt)[0];
      const digit = dt[key].digits;
      let name = dt[key].name;
      if(is_same_name) {
        if(index === 0) {
          name += 'A';
        } else {
          name += 'B';
        };
      };
      return (
        <div className={`${styles.values_area} ${styles.is_two}`} key={`div-${index}`}>
          <MediaQuery query="(max-width: 767px)">
            <div>
              <span >[</span>
              <span className={index === 0 ? styles.first : styles.second}>{name}</span>
              <span>]</span>
            </div>
            <div>最大<span className={styles.none}>値</span>:<span className={styles.max}>{dt[key].max.toFixed(digit)}</span></div>
            <div>最小<span className={styles.none}>値</span>:<span className={styles.min}>{dt[key].min.toFixed(digit)}</span></div>
            <div>平均<span className={styles.none}>値</span>:<span className={styles.average}>{dt[key].average.toFixed(digit)}</span></div>
            <div>[{dt[key].unit}]</div>
          </MediaQuery>
          <MediaQuery query="(min-width: 768px)">
            <div className={styles.legend_wrapper}>
              <i className={index === 0 ? `fas fa-square ${styles.legend}` : `fas fa-square ${styles.legend2}`}></i>
            </div>
            <div>
              <span >[</span>{name}<span>]</span>
            </div>
            <div>最大値: <span className={styles.max}>{dt[key].max.toFixed(digit)}</span></div>
            <div>最小値: <span className={styles.min}>{dt[key].min.toFixed(digit)}</span></div>
            <div>平均値: <span className={styles.average}>{dt[key].average.toFixed(digit)}</span></div>
            <div>[{dt[key].unit}]</div>
          </MediaQuery>
        </div>
      );
    });
    values_area = (
      <div className={styles.values_area_outer}>
        <div className={styles.values_area_wrapper_2}>
            { divs }
        </div>
      </div>
    );
  } else {
    values_area = values.map((dt, index) => {
        const key = Object.keys(dt)[0];
        const digit = dt[key].digits;
        return (
          <div className={styles.values_area_outer} key={index}>
            <div className={styles.values_area_wrapper_1}>
                <div className={styles.values_area}>
                  <div>最大値: <span className={styles.max}>{dt[key].max.toFixed(digit)}{dt[key].unit}</span></div>
                  <div>最小値: <span className={styles.min}>{dt[key].min.toFixed(digit)}{dt[key].unit}</span></div>
                  <div>平均値: <span className={styles.average}>{dt[key].average.toFixed(digit)}{dt[key].unit}</span></div>
                </div>
            </div>
          </div>
        );
      });
  }
  return values_area;
};