import * as types from '../actions/actionTypes';
import * as actions from '../actions';
import { put, take, call, all } from 'redux-saga/effects';
import awsAPI from '../../aws';
import { Auth } from 'aws-amplify';
import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync';
import { appSync } from '../../config/index';

const client = new AWSAppSyncClient({
  url: appSync.url,
  region: appSync.region,
  auth: {
    type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
    jwtToken: async () => (await Auth.currentSession()).getIdToken().getJwtToken(),
  },
  disableOffline: true
});

function appException(message) {
  this.message = message;
  this.name = 'appException';
};

// function callBackEnd(data, api) {
//   return awsAPI.Post(api.endpoint, data)
//     .then((resp) => {
//       if (resp.data.done === false) {
//         throw new appException(`Error: ${resp.data.res_data}`);
//       }
//       return resp.data.res_data;
//     })
//     .catch((error) => {
//       throw error
//     });
// }

// function callOpenBackEndWithGet(api) {
//   return awsAPI.openGet(api.endpoint)
//     .then((resp) => {
//       if (resp.data.done === false) {
//         throw new appException(`Error: ${resp.data.res_data}`);
//       }
//       return resp.data.res_data;
//     })
//     .catch((error) => {
//       throw error
//     });
// };

function callBackEndWithGet(data, api, jwtToken) {
  return awsAPI.Get(api.endpoint, data, jwtToken)
    .then((resp) => {
      if (resp.data.done === false) {
        throw new appException(`Error: ${resp.data.res_data}`);
      }
      return resp.data.res_data;
    })
    .catch((error) => {
      throw error
    });
};

function callBackEndWithPost(data, api, jwtToken) {
  return awsAPI.Post(api.endpoint, data, jwtToken)
     .then((resp) => {
        if (resp.data.done === false) {
           throw new appException(`Error: ${resp.data.res_data}`);
        }
        return resp.data.res_data;
     })
     .catch((error) => {
        throw error
     });
};


function getCallItems(callBackEndWithGet, param, api, jwtToken) {
  return call(callBackEndWithGet, param, api, jwtToken);
};

function getLatestToken() { 
  return Auth.currentAuthenticatedUser() 
     .then(user => { 
        // User is logged in.  
        return user.signInUserSession.idToken.jwtToken 
     }) 
     .catch(() => { 
        // No logged-in user  
        return null 
     }) 
};

export function graphql(query, variables) {
  return client.query({query: query, variables: variables, fetchPolicy: 'network-only'}).then(result => result);
};

export function* processAppSync(metaType) {
  while (true) {
    const action = yield take([
      types.data.GRAPHQL_REQUESTED,
    ])
    if (action.type === types.data.GRAPHQL_REQUESTED && action.meta.type === metaType) {
      let fulfilledFunc = `${action.meta.type}Fulfilled`;
      let rejectedFunc = `${action.meta.type}Rejected`;
      try {
        let data;
        if(action.meta.type === 'getLatestAlertHistory') {
          let requests = [];
          action.payload.forEach((param) => {
            requests.push(call(graphql, param.query, param.variables));
          });
          const response = yield all(requests);
          data = {};
          response.forEach((res) => {
            const items = res.data.getLatestAlertHistory.items;
            if(items.length) {
              const id = items[0].id;
              const errorCode = items[0].errorCode;
              data[id] = {
                errorCode
              };
            }
          });
        } else {
          data = yield call(graphql, action.payload.query, action.payload.variables);
        }
        yield put(actions.data[fulfilledFunc](data));
      } catch (error) {
        yield put(actions.data[rejectedFunc](error));
        console.log(error)
      }
    }
  }
}

export function* processAppActions(metaType) {
  while (true) {
    const action = yield take([
      types.data.GET_DATA_REQUESTED,
      types.data.POST_DATA_REQUESTED,
    ])
    if(action.meta.type === metaType) {
      const fulfilledFunc = `${action.meta.type}Fulfilled`;
      const rejectedFunc = `${action.meta.type}Rejected`;
      if (action.type === types.data.GET_DATA_REQUESTED) {
        try {
          let data;
          if(action.meta.type === 'getGraphDataOf24Hour' ||
              action.meta.type === 'getGraphDataOf1Hour' ||
              action.meta.type === 'getGraphDataOf10Minutes' ||
              action.meta.type === 'getGraphDataOf1Minute' ||
              action.meta.type === 'getGraphDataOfSelectedTerm' ||
              action.meta.type === 'get1HourGraphDataOfHourRateLevel' ||
              action.meta.type === 'getRainOneDayGraphData' ||
              action.meta.type === 'getRain1HourGraphData' ||
              action.meta.type === 'getRain10MinutesGraphData' ||
              action.meta.type === 'getRainTotalValuesPerPeriod') {
                const jwtToken = yield call(getLatestToken);
                data = [];
                let requests = [];
                action.payload.forEach((param) => {
                  requests.push(getCallItems(callBackEndWithGet, param, action.meta.api, jwtToken));
                });
                const gotData = yield all(requests);
                gotData.forEach((dt) => {
                  data.push(JSON.parse(dt));
                });
          } else {
            const jwtToken = yield call(getLatestToken);
            data = yield call(callBackEndWithGet, action.payload, action.meta.api, jwtToken);
            data = JSON.parse(data);
          }
          yield put(actions.data[fulfilledFunc](data));
        } catch (error) {
          yield put(actions.data[rejectedFunc](error));
  
          if(error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          }
          if(error.request) {
            console.log(error.request);
          }
  
          yield put(actions.app.showAlert({
            alert_type: 'fail',
            alert_message: error.message ? error.message : error
          }));
  
          console.log(error);
        }
      } else if(action.type === types.data.POST_DATA_REQUESTED) {
        try {
          const jwtToken = yield call(getLatestToken);
          yield call(callBackEndWithPost, action.payload, action.meta.api, jwtToken);
          yield put(actions.data[fulfilledFunc]());
        } catch (error) {
          yield put(actions.data[rejectedFunc](error));
  
          if(error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          }
          if(error.request) {
            console.log(error.request);
          }
  
          yield put(actions.app.showAlert({
            alert_type: 'fail',
            alert_message: error.message ? error.message : error
          }));
  
          console.log(error);
        } 
      }
    }
  }
}
