import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AsyncComponent from './asyncComponent';
import UnauthenticatedRoute from './unauthenticatedRoute';
import AuthenticatedRoute from './authenticatedRoute';
import Prepare from '../components/Layout/Prepare';
import NotFound from "../components/NotFound/NotFound";
import PrintPage from '../containers/pages/PrintPage/PrintPage';

const AsyncMeasurementScreenPage = AsyncComponent(() => {
  return import('../containers/pages/MeasurementScreenPage/MeasurementScreenPage')
});

const AsyncTopPage = AsyncComponent(() => {
  return import('../containers/pages/TopPage/TopPage')
});

const AsyncCheckingDataPage = AsyncComponent(() => {
  return import('../containers/pages/CheckingDataPage/CheckingDataPage')
});

const AsyncDownloadPage = AsyncComponent(() => {
  return import('../containers/pages/DownloadPage/DownloadPage')
});

const AsyncLoginPage = AsyncComponent(() => {
  return import('../containers/pages/LoginPage/LoginPage')
});

const AsyncAdminLoginPage = AsyncComponent(() => {
  return import('../containers/pages/AdminLoginPage/AdminLoginPage')
});

const AsyncAlertSettingPage = AsyncComponent(() => {
  return import('../containers/pages/AlertSettingPage/AlertSettingPage')
});

const AsyncAlertHistoryPage = AsyncComponent(() => {
  return import('../containers/pages/AlertHistoryPage/AlertHistoryPage')
});

export default (props) =>
  <Prepare>
    <Switch>
      <AuthenticatedRoute path="/" exact component={AsyncTopPage} props={props} />
      <AuthenticatedRoute path="/measurement-screen/:siteId" exact component={AsyncMeasurementScreenPage} props={props} />
      <AuthenticatedRoute path="/check-data/:siteId" exact component={AsyncCheckingDataPage} props={props} />
      <AuthenticatedRoute path="/download/:siteId" exact component={AsyncDownloadPage} props={props} />
      <AuthenticatedRoute path="/alert-setting/:siteId" exact component={AsyncAlertSettingPage} props={props} />
      <AuthenticatedRoute path="/alert-history/:siteId" exact component={AsyncAlertHistoryPage} props={props} />
      <AuthenticatedRoute path="/print/:siteId" exact component={PrintPage} props={props} />
      <UnauthenticatedRoute path="/login" exact component={AsyncLoginPage} props={props} />
      <UnauthenticatedRoute path="/admin" exact component={AsyncAdminLoginPage} props={props} />
      <Route component={NotFound} />
    </Switch>
  </Prepare>;
