import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Routes from './routes/routes';
import { connect } from 'react-redux';
import gql from 'graphql-tag';
import * as actions from './store/actions/index';
import Alert from './components/Alert/Alert';
import Modal from './components/Modal/Modal';
import Header from './components/Layout/Header/Header';
import HamburgerMenu from './components/HamburgerMenu/HamburgerMenu';
import MediaQuery from "react-responsive";

import { Auth } from 'aws-amplify';
import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync';
import { appSync } from './config/index';
import styles from './App.module.css';
const moment = require('moment-timezone');

const client = new AWSAppSyncClient({
  url: appSync.url,
  region: appSync.region,
  auth: {
    type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
    jwtToken: async () => (await Auth.currentSession()).getIdToken().getJwtToken(),
  },
  disableOffline: true
});

const getLatestAlertHistoryQuery = gql`
  query getLatestAlertHistory($id: String!, $timeStamp: String!) {
    getLatestAlertHistory(id: $id, timeStamp: $timeStamp) {
      items{
        id
        errorCode
      }
    }
  }
`;

const getUnreadAlertHistoryQuery = gql`
  query getUnreadAlertHistory($siteId: String!) {
    getUnreadAlertHistory(siteId: $siteId) {
      items{
        id
      }
    }
  }
`;

const subscriptionQuery =  gql`
  subscription alertSub($siteId: String!) {
    onCreateAlertHistory(siteId: $siteId) {
      id
      errorCode
    }
  }
`;

const defaultState = {
  siteAlertStatus: {},
  unreadNotificationNum: 0,
  doneInitialization: false,
  gotNewAlertHistory: false,
  doneGetUnreadAlertHistoryRequest: false,
  is_show_modal: false,
  siteId: undefined,
  doneSettingRequest: false
};

class App extends Component {
  state = {
    ...defaultState
  };

  componentDidMount() {
    this.props.checkLogin();
  }

  componentDidUpdate = async (prevProps, prevState) => {
    let newState = {};
    const siteId = this.props.location.pathname.split('/')[2];
    if(this.state.siteId !== siteId) {
      newState.siteId = siteId;
    }

    if(this.props.is_authenticated && this.state.siteId) {
      if(!this.state.doneSettingRequest) {
        this.props.getAlertSetting(this.state.siteId);
        this.props.getSetting(this.state.siteId);
        newState.doneSettingRequest = true;
      }

      if(this.props.alert_setting && prevProps.alert_setting !== this.props.alert_setting) {
        const alertSettings = this.props.alert_setting;
        let alertIds = [];
        if(alertSettings.hasOwnProperty('settings')) {
          if(alertSettings.settings.length) {
            alertSettings.settings.forEach(set => {
              if(set.isValid) {
                alertIds.push(set.id);
              }
            });
          }
        }
        if(alertIds.length) {
          const timeStamp = moment().tz('Asia/Tokyo').valueOf();
          const params = [];
          alertIds.forEach(id => {
            const obj = {
              query: getLatestAlertHistoryQuery,
              variables: {
                id,
                timeStamp
              }
            };
            params.push(obj);
          });
          this.props.getLatestAlertHistory(params);
        } else {
          if(Object.keys(this.state.siteAlertStatus).length > 0) {
            newState.siteAlertStatus = {}
          }
        }
      }
  
      // 最新のアラート状況の取得
      if(this.props.latest_alert_history && prevProps.latest_alert_history !== this.props.latest_alert_history) {
        newState.siteAlertStatus = this.props.latest_alert_history;
      }
  
      // 未読のアラート履歴を取得
      // 初期化中のみ処理が走る
      if(!this.state.doneGetUnreadAlertHistoryRequest) {
        const param = {
          query: getUnreadAlertHistoryQuery,
          variables: {
            siteId: this.state.siteId
          }
        };
        this.props.getUnreadAlertHistory(param);
        newState.doneGetUnreadAlertHistoryRequest = true;
      }
  
      // 未読のアラート履歴を取得したあとで以降の処理が走る（初期化中）
      // 以降はアラート履歴のstatusが既読に更新されたあとで処理が走る
      if(this.props.unread_alert_history　&& (prevProps.unread_alert_history !== this.props.unread_alert_history)) {
        const unreadNum = this.props.unread_alert_history.data.getUnreadAlertHistory.items.length;
        newState.unreadNotificationNum = unreadNum;
        if(!this.state.doneInitialization) {
          newState.doneInitialization = true;
        }
      }
  
      // 現在のアラート状況や未読のアラート件数などの取得処理が終わったあとでsubscriptionする
      if(!prevState.doneInitialization && this.state.doneInitialization) {
        const variables = {
          "siteId": this.state.siteId
        };
        this.subscription = client.subscribe({ query: subscriptionQuery, variables}).subscribe({
          next: sub => {
            const id = sub.data.onCreateAlertHistory.id;
            const errorCode = sub.data.onCreateAlertHistory.errorCode;
            let siteAlertStatus = {...this.state.siteAlertStatus};
            siteAlertStatus[id] = { errorCode };
            const param = {
              query: getUnreadAlertHistoryQuery,
              variables: {
                siteId: this.state.siteId
              }
            };
            this.props.getUnreadAlertHistory(param);
            this.setState({
              siteAlertStatus,
              gotNewAlertHistory: true
            });
          },
          error: error => {
            console.log(error);
            if(error.errorMessage === 'AMQJS0008I Socket closed.') {
              this.setState({
                is_show_modal: true
              });
            }
          }
        });
      }
  
      // アラート履歴のstatusが既読に更新されたら走る処理
      if(prevProps.updating_read_status === true && this.props.updating_read_status === false) {
        const param = {
          query: getUnreadAlertHistoryQuery,
          variables: {
            siteId: this.state.siteId
          }
        };
        this.props.getUnreadAlertHistory(param);
      }
    }
    // ログアウトしたらstateを初期値に戻す
    if(prevProps.is_authenticated && !this.props.is_authenticated) {
      this.setState(defaultState);
    }

    if(Object.keys(newState).length > 0) {
      this.setState(newState);
    }
  }

  componentWillUnmount(){
    if(this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  handleLogout = () => {
    this.props.logout();
    if(this.subscription) {
      this.subscription.unsubscribe();
    }
  };

  clearNewAlertHistory = (gotNewAlertHistory) => {
    if(gotNewAlertHistory) {
      this.setState({
        gotNewAlertHistory: false
      });
    }
  };

  reloadBrowser = () => {
    window.location.reload();
  };

  setDefaultState = (defaultState, is_admin) => {
    if(is_admin) {
      this.subscription.unsubscribe();
      this.setState(defaultState);
    }
  }

  render() {
    const pathname = this.props.location.pathname.split('/')[1];
    let header = null;
    if (['login', 'admin', 'print'].indexOf(pathname) < 0) {
      header = <Header
                 is_root={pathname === ''}
                 is_authenticated={this.props.is_authenticated}
                 handleLogout={this.handleLogout}
                 unreadNotificationNum={this.state.unreadNotificationNum}
                 siteAlertStatus={this.state.siteAlertStatus}
                 setDefaultState={() => this.setDefaultState(defaultState, this.props.is_admin)}
                 is_admin={this.props.is_admin}
               />
    }

    return (
      !this.props.is_authenticating &&
      <div className="App" style={{position: "relative"}} id="App">
        <Alert
          message={this.props.alert_message}
          show={this.props.show_alert}
          onClose={this.props.hideAlert}
        />
        <Modal
          show={this.state.is_show_modal}
          children={
            <div>
              <div className={styles.modal_message_area}>
                <i className="fas fa-exclamation-circle"></i>
                <p>サーバーとの通信が切断されました。<br/>ブラウザを再読み込みします</p>
                <br/>
              </div>
              <div>
                <button
                  className={styles.modal_close_btn}
                  onClick={this.reloadBrowser}>はい</button>
              </div>
            </div>
          }
        />

        <MediaQuery query="(max-width: 1023px)">
          <HamburgerMenu
            isAdmin={this.props.is_admin}
            is_authenticated={this.props.is_authenticated}
            unreadNotificationNum={this.state.unreadNotificationNum}
            pageWrapId={"page-wrap"}
            outerContainerId={"App"}
          />
        </MediaQuery>

        <div id="page-wrap">
          { header }
          <Routes
            is_authenticated={this.props.is_authenticated}
            is_admin={this.props.is_admin}
            is_loggingOut={this.props.is_loggingOut}
            user={this.props.user}
            handleLogout={this.handleLogout}
            unreadNotifications={this.state.unreadNotifications}
            gotNewAlertHistory={this.state.gotNewAlertHistory}
            clearNewAlertHistory={this.clearNewAlertHistory}
            loanStatus={this.props.setting.loanStatus}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    alert_message: state.app.alert_message,
    show_alert: state.app.show_alert,
    is_authenticating: state.auth.is_authenticating,
    is_authenticated: state.auth.is_authenticated,
    is_loggingOut: state.auth.logout.inProgress,
    is_admin: state.auth.is_admin,
    user: state.auth.user,
    alert_setting: state.app.get_alert_setting,
    setting: state.app.get_setting,
    latest_alert_history: state.app.latest_alert_history,
    unread_alert_history: state.app.unread_alert_history,
    updating_read_status: state.app.updateReadStatus.inProgress
  }
}

const mapDispatchToProps = dispatch => {
  return {
    hideAlert: () => dispatch(actions.app.hideAlert()),
    checkLogin: () => dispatch(actions.auth.checkLogin()),
    logout: () => dispatch(actions.auth.logout()),
    getAlertSetting: (data) => dispatch(actions.data.getAlertSettingRequested(data)),
    getSetting: (data) => dispatch(actions.data.getSettingRequested(data)),
    getLatestAlertHistory: (params) => dispatch(actions.data.getLatestAlertHistoryRequested(params)),
    getUnreadAlertHistory: (param) => dispatch(actions.data.getUnreadAlertHistoryRequested(param)),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
