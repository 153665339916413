import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const renderComponent = (C, props, cProps) => {
  const directories = window.location.pathname.split('/');
  if (!cProps.is_authenticated) {
    if (!props.location.pathname) return <Redirect to='/admin' />;
    if (['/'].indexOf(props.location.pathname) > -1 || directories.length < 2) return <Redirect to={`/admin?redirect=${props.location.pathname}${props.location.search}`} />;
    return <Redirect to={`/login?redirect=${props.location.pathname}${props.location.search}`} />;
  }
  if (!directories[0]) directories[0] = 'measurement-screen';
  if (!cProps.is_admin && directories[1] === 'check-data') return <Redirect to={`/${directories[0]}/${cProps.user.username}`} />;
  if (!cProps.is_admin && (directories.length < 2 || directories[2] !== cProps.user.username)) return <Redirect to={`/${directories[0]}/${cProps.user.username}`} />;
  return <C {...props} {...cProps} />;  
}

export default ({ component: C, props: cProps, ...rest }) => {
  return <Route
    {...rest}
    render={props => renderComponent(C, props, cProps) }
  />;
};
