import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { slide as Menu } from 'react-burger-menu';
import styles from './HamburgerMenu.module.css';
import './style.css';



function HamburgerMenu({
  isAdmin,
  is_authenticated,
  unreadNotificationNum,
  pageWrapId,
  outerContainerId
}) {
  const [isOpen, setIsOpen] = useState(false);
  
  if(!is_authenticated) {
    return null;
  }

  const siteId = window.location.pathname.split('/')[2];
  const pathname = window.location.pathname.split('/')[1];
  // トップページだった場合表示しない
  if(!pathname) {
    return null;
  }
  return (
    <Menu
      pageWrapId={pageWrapId}
      outerContainerId={outerContainerId}
      isOpen={isOpen}
      onStateChange={(state) => setIsOpen(state.isOpen)}>
      <div className={styles.menu}><i className="far fa-list-alt"></i>メニュー</div>
      <Link
        to={`/measurement-screen/${siteId}`}
        className={pathname === 'measurement-screen' ? styles.active : styles.nomal_color}
        onClick={() => setIsOpen(false)}
      >
        測定データ
      </Link>
      {
        !isAdmin
          ? null
          : (
            <Link
              to={`/check-data/${siteId}`}
              className={pathname === 'check-data' ? styles.active : styles.nomal_color}
              onClick={() => setIsOpen(false)}
            >
              測定データリスト
            </Link>
          )
      }
      <div className={styles.alert_note_link}>
        { unreadNotificationNum
          ? <div className={styles.badge}>{unreadNotificationNum}</div>
          : null
        }
        <Link
          to={`/alert-history/${siteId}`}
          className={pathname === 'alert-history' ? styles.active : styles.nomal_color}
          onClick={() => setIsOpen(false)}
          >
            アラート履歴
        </Link>
      </div>
      <Link
        to={`/alert-setting/${siteId}`}
        className={pathname === 'alert-setting' ? styles.active : styles.nomal_color}
        onClick={() => setIsOpen(false)}
        >
          アラート設定
      </Link>
    </Menu>
  )
}

export default HamburgerMenu;
