import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Header.module.css';
import MediaQuery from "react-responsive";
const queryString = require('query-string');

function Header(props) {
  if(!props.is_authenticated) {
    return (
      <header className={`${styles.header } ${styles.notfound_header}`}>
        <Link
          to="/"
          className={styles.link_logo}
          onClick={props.setDefaultState}>
          <img className={styles.logo} src="../images/header_logo.png" alt="logo" />
        </Link>
      </header>
    );
  }

  const isRoot = props.is_root;
  let siteId = window.location.pathname.split('/')[2];
  let pathname = window.location.pathname.split('/')[1];
  if(!siteId) {
    siteId = queryString.parse(window.location.search).site;
  }

  // 現場で今現在エラーが起きているかどうかの値を保持
  let inError = false;

  const siteAlertStatus = props.siteAlertStatus;
  const keys = Object.keys(siteAlertStatus);
  if(keys.length) {
    const errorCodes = [];
    keys.forEach(key => {
      errorCodes.push(siteAlertStatus[key].errorCode);
    });
    if(errorCodes.includes(1)) {
      inError = true;
    }
  }
  return (
    <div>
      <header className={styles.header}>
        <MediaQuery query="(max-width: 1023px)">
          <Link
            to="/"
            onClick={props.setDefaultState}
            className={styles.link_logo}
          >
            <img className={styles.logo} src="../images/header_logo.png" alt="logo" />
          </Link>
          {
            inError && !isRoot
              ? (
                <div className={styles.alert}>
                  <Link
                    to={`/alert-history/${siteId}`}
                  >
                    <i className={`fas fa-bell ${styles.error}`}></i>
                  </Link>
                </div>
              )
              : (
                <div className={styles.alert}>
                  <i className="fas fa-bell"></i>
                </div>
              )
          }
          <Link
            to={isRoot ? '/' : `/login?redirect=/measurement-screen/${siteId}`}
            className={styles.logout}
            onClick={props.handleLogout}>
            <i className="fas fa-sign-out-alt"></i><span className={styles.logout_str}>ログアウト</span>
          </Link>
        </MediaQuery>

        <MediaQuery query="(min-width: 1024px)">
        <div>
          <header className={styles.header}>
            <Link
              to="/"
              onClick={props.setDefaultState}>
              <img src="../images/header_logo.png" alt="logo" />
            </Link>
            { isRoot ? null : <Link
              to={`/measurement-screen/${siteId}`}
              className={pathname === 'measurement-screen' ? styles.active : ''}
            >
              測定データ
            </Link> }
            { isRoot || !props.is_admin ? null : <Link
              to={`/check-data/${siteId}`}
              className={pathname === 'check-data' ? styles.active : ''}
            >
              測定データリスト
            </Link> }
            { isRoot ? null : <Link
              to={`/download/${siteId}`}
              className={pathname === 'download' ? styles.active : ''}
              >
                ダウンロード
            </Link> }
            { isRoot ? null : <div className={styles.alert_note_link}>
              { props.unreadNotificationNum
                ? <div className={styles.badge}>{props.unreadNotificationNum}</div>
                : null
              }
              <Link
                to={`/alert-history/${siteId}`}
                className={pathname === 'alert-history' ? styles.active : ''}
                >
                  アラート履歴
              </Link>
            </div> }
            { isRoot ? null : <Link
              to={`/alert-setting/${siteId}`}
              className={pathname === 'alert-setting' ? styles.active : ''}
              >
                アラート設定
            </Link> }
            {
              inError && !isRoot
                ? (
                  <div className={styles.alert}>
                    <Link
                      to={`/alert-history/${siteId}`}
                    >
                      <i className={`fas fa-bell ${styles.error}`}></i>
                    </Link>
                  </div>
                )
                : (
                  <div className={styles.alert}>
                    <i className="fas fa-bell"></i>
                  </div>
                )
            }
            <Link
              to={isRoot ? '/' : `/login?redirect=/measurement-screen/${siteId}`}
              className={styles.logout}
              onClick={props.handleLogout}>
              <i className="fas fa-sign-out-alt"></i>ログアウト
            </Link>
          </header>
        </div>
      </MediaQuery>
      </header>
    </div>
  )
}

export default Header;
