module.exports = (value, calc, digits) => {
  if(isNaN(digits)) {
    digits = 0;
  }
  if(digits === 0) {
    return Function('"use strict";return Math.round(' + calc.replace(/v/, value)  + ');')();
  }

  let num = '1';
  for(let i = 0; i < digits; i++) {
    num += '0';
  }
  num = Number(num);
  return Function('"use strict";return Math.round((' + calc.replace(/v/, value)  + ') *' + num + ') /' +  num + ';')();
};