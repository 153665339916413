import React, { Component } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, ResponsiveContainer } from '@bitriver/recharts';
import styles from './BarChartComponent.module.css';
import '../../style.css';
import getCalculatedValue from '../../../../utils/getCalculatedValue';
const moment = require('moment-timezone');
moment.updateLocale('ja', {
  weekdaysShort: ["日","月","火","水","木","金","土"],
});

// 1分間に30mmを超えない想定
const LIMIT = 100;

class BarChartComponent extends Component {
  shouldComponentUpdate(nextProps) {
    if(this.props.params.length === nextProps.params.length) {
      return false
    } else {
      return true
    }
  }

  // データの欠損の穴埋めや、計算が必要なものはデータの算出をする
  getFormattedGraphData = (graph_data, is_sensu, formula, digits, diff_hour, start, end) => {
    let formatted_graph_data;
    if(is_sensu && diff_hour <= 3) {
      let prevCount;
      for(let i = 0; i < graph_data.length; i++) {
        if(prevCount !== undefined) {
          let value = graph_data[i].count - prevCount;
          if(value < 0) {
            value = value + 65536;
          }
          if(value > LIMIT) {
            value = 0;
          }
          graph_data[i].value = getCalculatedValue(value, formula, digits);
        }
        prevCount = graph_data[i].count;
      }
      // 先頭のデータは計算のためだけに必要だったのでで計算後に削除する
      graph_data.shift();
    };
    const start_date = (is_sensu && diff_hour <= 3) ? moment(start + 60000).tz('Asia/Tokyo') : moment(start).tz('Asia/Tokyo');
    const end_date = moment(end).tz('Asia/Tokyo');
    let i = 0;
    const base_date = start_date.clone().add(1, 'milliseconds');
    let graph_array = [];
    while(base_date.isBefore(end_date, 'second')) {
      const obj = {};
      if(graph_data[i]) {
        const date_from_graph_data = moment(graph_data[i].date).tz('Asia/Tokyo');
        if(diff_hour > 3) {
          // 10分データ作成時にcronの発火ミスで10分刻み以外のデータが作成されることがある。もし半端なデータが来た場合はここで弾く
          const strMinutes = String(date_from_graph_data.minutes());
          const targetMinutes = strMinutes.length === 1 ? strMinutes : strMinutes.slice(-1);
          if(targetMinutes !== "0") {
            i++;
            continue;
          }
        }
        
        if(date_from_graph_data.isSame(base_date, 'minutes')) {
          obj.date = date_from_graph_data.format("YYYY年MM月DD日 HH:mm");
          obj.value = graph_data[i].value;
          i++;
        } else {
          obj.date = base_date.format("YYYY年MM月DD日 HH:mm");
          obj.value = null;
        }
      } else {
        obj.date = base_date.format("YYYY年MM月DD日 HH:mm");
        obj.value = null;
        i++;
      }
      if(diff_hour <= 3) {
        base_date.add(1, 'minutes');
      } else {
        base_date.add(10, 'minutes');
      }
      graph_array.push(obj);
    }
    formatted_graph_data = graph_array;
    return formatted_graph_data;
  };

  getXaxisParams = (diff_hour) => {
    const tickFormatter = (props) => {
      const time = props.split(' ')[1];
      const splitedTime = time.split(':');
      const reTime = `${splitedTime[0]}:${splitedTime[1]}`;
      return reTime;
    };

    let interval= 0;
    if(diff_hour > 48) {
      interval = 35;
    } else if(diff_hour > 24) {
      interval = 11;
    } else if(diff_hour >= 8) {
      interval = 5;
    } else if(diff_hour > 3) {
      interval = 2;
    } else if(diff_hour > 1) {
      interval = 9;
    } else if(diff_hour > 0.33) { // 20分以上
      interval = 4;
    } else if(diff_hour >= 0.06) { // 4分以上
      interval = 0;
    }
    const xAxis_params = {
      interval, tickFormatter,
      height: 45,
      dy: 10,
      angle: -35
    };
    return xAxis_params;
  };

  getYaxisDomain = (min_value, max_value, min_of_graph_range, max_of_graph_range, value_to_change_graph_range) => {
    // グラフに表示される値が全て15などの場合、y軸が10~20になる。
    // その時にどこかにnullの値が混じっているとそのnullの棒グラフがグラフを下に突き抜けて描画されてしまう現象が起きた。
    // min_of_graph_rangeを0に固定しておくとこの現象は起こらないため、0に設定することにした
    let domain = [0, Math.ceil(max_value) + 5];
    if(min_of_graph_range !== undefined && max_of_graph_range !== undefined && value_to_change_graph_range !== undefined) {
      if(min_value >= min_of_graph_range && max_value <= max_of_graph_range) {
        domain = [0, max_of_graph_range];
      } else {
        const data_abs = Math.abs(Math.round((max_value - min_value) * 100) / 100);
        const graph_range_abs = Math.abs(Math.round((max_of_graph_range - min_of_graph_range) * 100) / 100);
        if(data_abs <= graph_range_abs) {
          let shift_width;
          if(max_value >= max_of_graph_range) {
            shift_width = Math.ceil((Math.round((max_value - max_of_graph_range) * 100) / 100) / value_to_change_graph_range) * value_to_change_graph_range;
          } else {
            shift_width = Math.floor((Math.round((min_value - min_of_graph_range) * 100) / 100) / value_to_change_graph_range) * value_to_change_graph_range;
          }
          max_of_graph_range = max_of_graph_range + shift_width;
          min_of_graph_range = min_of_graph_range + shift_width;
          domain = [0, max_of_graph_range];
        } else {
          if(max_value > max_of_graph_range) {
            const shift_width_of_max_range = Math.ceil((Math.round((max_value - max_of_graph_range) * 100) / 100) / value_to_change_graph_range) * value_to_change_graph_range;
            max_of_graph_range = max_of_graph_range + shift_width_of_max_range;
          };
          if(min_value < min_of_graph_range) {
            const shift_width_of_min_range = Math.floor((Math.round((min_value - min_of_graph_range) * 100) / 100) / value_to_change_graph_range) * value_to_change_graph_range;
            min_of_graph_range = min_of_graph_range + shift_width_of_min_range;
          };
          domain = [0, max_of_graph_range];
        };
      };
    };
    return domain;
  };

  getYaxisTickSpan = (yAxis_domain) => {
    const THRESHOLD_NUMBER_Of_TICKS = 7;
    const mantissa_table = [1, 2, 5, 10, 20, 50];
    const min_of_graph_range = yAxis_domain[0];
    const max_of_graph_range = yAxis_domain[1];
    const span = Math.round((max_of_graph_range - min_of_graph_range) * 100) / 100;
    const index = Math.floor(Math.log10(span)) -1;
    let tick_span;
    for(let i = 0; i < mantissa_table.length; i++) {
      tick_span = mantissa_table[i] * Math.pow(10, index);
      const number_of_ticks = Math.floor(span / tick_span);
      if(number_of_ticks < THRESHOLD_NUMBER_Of_TICKS) {
        break;
      };
    };
    return tick_span;
  };

  getYaxisTicks = (yAxis_domain, yAxis_tick_span) => {
    const min_of_graph_range = yAxis_domain[0];
    const max_of_graph_range = yAxis_domain[1];
    let ticks = [];
    let tick_value = min_of_graph_range;
    while(tick_value < max_of_graph_range) {
      tick_value = Math.round((tick_value + yAxis_tick_span) * 10) / 10;
      if(tick_value < max_of_graph_range) {
        ticks.push(tick_value);
      };
    };
    const yAxis_ticks = [min_of_graph_range, ...ticks, max_of_graph_range];
    return yAxis_ticks;
  };

  render() {
    const params = this.props.params[0];
    const diff_hour = params.diff_hour;
    const is_sensu = params.graph_type.split('<<<')[1].indexOf('sensu') >= 0 ? true : false;
    const formula = params.formula;
    const digits = params.digits
    const max_of_graph_range = params.max_of_graph_range;
    const min_of_graph_range = params.min_of_graph_range;
    const value_to_change_graph_range = params.value_to_change_graph_range;
    const start = params.start;
    const end = params.end;

    // props.dataをそのまま修正するとstateが書き換わってしまうため別の配列にコピーする
    let graph_data = [];
    for(let i = 0; i < params.graph_data.length; i++) {
      graph_data.push({...params.graph_data[i]});
    };

    // 単位を特定する
    let unit;
    for(let i = 0; i < graph_data.length; i++) {
      if(unit) {
        break;
      }
      if(graph_data[i].unit) {
        unit = graph_data[i].unit;
      }
    };

    const formatted_graph_data = this.getFormattedGraphData(graph_data, is_sensu, formula, digits, diff_hour, start, end);
    const max_value = Math.max(...formatted_graph_data.map(dt => dt.value));
    let filteredValue = [];
    formatted_graph_data.forEach((dt) => {
      if((typeof dt.value === 'number') && (isFinite(dt.value))) {
        filteredValue.push(dt.value);
      }
    });
    let min_value = 0;
    if(filteredValue.length) {
      min_value = Math.min(...filteredValue);
    };

    const yAxis_domain = this.getYaxisDomain(min_value, max_value, min_of_graph_range, max_of_graph_range, value_to_change_graph_range);
    const yAxis_tick_span = this.getYaxisTickSpan(yAxis_domain);
    const yAxis_ticks = this.getYaxisTicks(yAxis_domain, yAxis_tick_span);
    const xAxis_params = this.getXaxisParams(diff_hour);

    const margin = {
      top: 30,
      right: 50,
      bottom: 20,
      left: 15
    };

    return (
      <React.Fragment>
        <div className={styles.chart_wrapper}>
          <h2>{params.name}</h2>
          <div className={styles.yAxis_unit_wrapper}>
          <div className={styles.yAxis_unit}>
            {unit ? `[ ${unit} ]` : null}
          </div>
          </div>
          <ResponsiveContainer>
            <BarChart data={formatted_graph_data} margin={margin}>
              <CartesianGrid strokeDasharray="5 5" />
              <XAxis
                dataKey="date"
                interval={xAxis_params.interval}
                height={xAxis_params.height}
                dy={xAxis_params.dy}
                angle={xAxis_params.angle}
                tickFormatter={xAxis_params.tickFormatter}
              />
              <YAxis
                domain={yAxis_domain}
                ticks={yAxis_ticks}
                interval={0}
              />
              <Bar
                dataKey="value"
                fill="#8884d8"
                unit={unit}
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </React.Fragment>
    )
  }
}

export default BarChartComponent;
