import React, { Component } from 'react';
import { ScatterChart, Scatter, CartesianGrid, XAxis, YAxis, ResponsiveContainer } from '@bitriver/recharts';
import CustomizedDot from './CustomizedDot';
import styles from './ScatterChartComponent.module.css';
const moment = require('moment-timezone');
moment.updateLocale('ja', {
  weekdaysShort: ["日","月","火","水","木","金","土"],
});

class ScatterChartComponent extends Component {
  state = {
    startIndex: this.props.start_index,
    endIndex: this.props.end_index
  };

  getXaxisParams = (diff_hour) => {
    const tickFormatter = (props) => {
      const time = props.split(' ')[1];
      const splitedTime = time.split(':');
      const reTime = `${splitedTime[0]}:${splitedTime[1]}`;
      return reTime;
    };

    let interval = 5;
    if(diff_hour > 48) {
      interval = 35;
    } else if(diff_hour > 24) {
      interval = 11;
    } else if(diff_hour > 8) {
      interval = 5;
    } else if(diff_hour > 3) {
      interval = 2;
    } else if(diff_hour > 1) {
      interval = 9;
    } else if(diff_hour > 0.33) {  // 20分以上
      interval = 4;
    } else {  // 4分以上
      interval = 0;
    }
    const xAxis_params = {
      interval, tickFormatter,
      height: 45,
      angle: -35,
      dy: 10
    };
    return xAxis_params;
  };

  render() {
    const params = this.props.params;
    const { startIndex, endIndex } = this.state;
    const diff_hour = params.diff_hour;
    const graph_data = params.graph_data.slice(startIndex, endIndex + 1);
    const xAxis_params = this.getXaxisParams(diff_hour);
    
    const margin = {
      top: 30,
      right: 50,
      bottom: 20,
      left: 15
    };

    return (
      <React.Fragment>
        <div className={styles.chart_wrapper}>
          { <h2>{params.name}</h2> }
          <div className={styles.yAxis_unit_wrapper}>
            <div className={styles.yAxis_unit}>
            </div>
          </div>
          <ResponsiveContainer>
            <ScatterChart
              margin={margin}
              data={this.props.date_array}
            >
              <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
              <XAxis
                dataKey="date"
                height={xAxis_params.height}
                interval={xAxis_params.interval}
                dy={xAxis_params.dy}
                angle={xAxis_params.angle}
                tickFormatter={xAxis_params.tickFormatter}
                allowDuplicatedCategory={false}
              />
              <YAxis
                name='value'
                yAxisId='left'
                dataKey="value"
                domain={[0, 360]}
                ticks={[0, 90, 180, 270, 360]}
                interval={0}
                dx={-5}
                orientation='left'
                stroke='#212529'
                tickFormatter={(props) => {
                    let direction;
                    if(props === 0) {
                        direction = '北';
                    };
                    if(props === 90) {
                        direction = '東';
                    };
                    if(props === 180) {
                        direction = '南';
                    };
                    if(props === 270) {
                        direction = '西';
                    };
                    if(props === 360) {
                        direction = '北';
                    };
                    return direction;
                }}
              />
              <Scatter
                yAxisId='left'
                data={graph_data}
                fill='#8884d8'
                shape={<CustomizedDot r={4} color='#8884d8' />}
              />
            </ScatterChart>
          </ResponsiveContainer>
        </div>
      </React.Fragment>
    )
  }
}

export default ScatterChartComponent;
