import createReducer from './createReducer';
import * as types from '../actions/actionTypes';

const initialSubState = {
  inProgress: false,
  error: null
};

const initialState = {
  is_admin: false,
  is_authenticated: false,
  is_authenticating: true,
  user: null,
  jwtToken: null,
  login: initialSubState,
  logout: initialSubState,
  check_login: initialSubState
};

export const authReducer = createReducer(initialState, {
  [types.auth.CHECK_LOGIN_REQUESTED](state, action) {
    let newState = {
      ...initialState,
      is_authenticating: true,
      check_login: {
        inProgress: true,
        error: null
      }
    }
    return newState;
  },
  [types.auth.CHECK_LOGIN_FULFILLED](state, action) {
    const user = action.payload.user;
    const jwtToken = user.signInUserSession.idToken.jwtToken;
    let newState = {
      ...state,
      is_authenticating: false,
      is_authenticated: true,
      is_admin: action.payload.isAdmin,
      user,
      jwtToken,
      check_login: { ...initialSubState }
    }
    return newState;
  },
  [types.auth.CHECK_LOGIN_REJECTED](state, action) {
    const error = action.payload.error;
    let newState = {
      ...state,
      is_authenticating: false,
      check_login: {
        error,
        inProgress: false
      }
    }
    return newState;
  },
  [types.auth.LOGIN_REQUESTED](state, action) {
    return {
      ...initialState,
      is_authenticating: false,
      login: {
        inProgress: true,
        error: null
      }
    }
  },
  [types.auth.LOGIN_FULFILLED](state, action) {
    const user = action.payload.user;
    const jwtToken = user.signInUserSession.idToken.jwtToken;
    let newState = {
      ...state,
      user,
      jwtToken,
      is_authenticating: false,
      is_authenticated: true,
      is_admin: action.payload.isAdmin,
      login: {...initialState}
    }
    return newState;
  },
  [types.auth.LOGIN_REJECTED](state, action) {
    const error = action.payload.error;
    let newState = {
      ...state,
      is_authenticating: false,
      login: {
        error,
        inProgress: false
      }
    }
    return newState;
  },
  [types.auth.LOGOUT_REQUESTED](state, action) {
    let newState = {
      ...state,
      logout: {
        error: null,
        inProgress: true
      }
    }
    return newState;
  },
  [types.auth.LOGOUT_FULFILLED](state, action) {
    return {
      ...initialState,
      is_authenticating: false
    }
  },
  [types.auth.LOGOUT_REJECTED](state, action) {
    const error = action.payload.error;
    let newState = {
      ...state,
      logout: {
        error,
        inProgress: false
      }
    }
    return newState;
  },
});
