import axios from 'axios';
import { API_URL } from '../config';

const awsCaller = axios.create({
  baseURL: API_URL
});

// export const Post = (path, data) => {
//   return new Promise((resolve, reject) => {
//     awsCaller.post(path, data)
//       .then((response) => resolve(response))
//       .catch((error) => reject(error));
//   });
// }

export const Post = (path, data, jwtToken) => {
  return new Promise((resolve, reject) => {
     awsCaller.post(path, data, {
        headers: {
           'Authorization': jwtToken
        }
     })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

export const Get = (path, data, jwtToken) => {
  return new Promise((resolve, reject) => {
    let p = path;
    if(data) {
      if(typeof data === 'object') {
        p += '?';
        let keys = Object.keys(data);
        keys.forEach((key) => {
          p += `${key}=${data[key]}&`;
        });
        p = p.slice(0, -1);
      } else {
        p = `${p}/${data}`;
      }
    }
    awsCaller.get(p, {
      headers: {
        'Authorization': jwtToken
      }
    })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

export const openGet = (path) => {
  return new Promise((resolve, reject) => {
    awsCaller.get(path)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}
